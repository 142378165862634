import React from 'react'
import {StaticImage} from 'gatsby-plugin-image'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Layout from '../components/layout'
import Seo from '../components/seo'
import CourseHeading from '../icons/courseHeading'
import ReserveButton from '../components/reserveButton'
import UnderArrowIcon from '../icons/underarrow'
import * as styles from '../styles/course.module.scss'

export default function Course() {
  const Headings = [
    // {
    //   course: 'コラーゲンカプセル',
    //   menu: [
    //     'test1',
    //     'test2'
    //   ]
    // },
    // {
    //   course: 'ホワイトニング',
    //   menu: [
    //     'test1',
    //     'test2'
    //   ]
    // },
    {
      course: '女性脱毛メニュー',
      menu: [
        '顔脱毛',
        'VIO脱毛',
        'パーツ脱毛'
      ],
      linkName: 'lremoval',
      link: '/course/#lremoval'
    },
    {
      course: '男性脱毛メニュー',
      menu: [
        'ヒゲ脱毛',
        'VIO脱毛',
        'パーツ脱毛'
      ],
      linkName: 'gremoval',
      link: '/course/#gremoval'
    }
  ]

  const courseHeading = (title, id) => (
    <h2 className={styles.course_heading} id={id}>{title}</h2>
  )
  const courseSubHeading = (title) => (
    <h3 className={styles.course_subheading}>{title}</h3>
  )

  // メインコンテンツ
  return (
    <Layout>
      <Seo
        pagetitle='コース一覧'
        pagedescription='パーティーズサロンのコース一覧です'
      />
      <section className={styles.top_title}>
        <h1 className={styles.top_heading}>
          <span className={styles.top_heading_icon}>
            <CourseHeading />
          </span>
          <span className={styles.top_heading_text}>コース一覧</span>
        </h1>
        <ul className={styles.anchor_list}>
          { Headings.map( (item, idx) =>
            <li key={idx} className={styles.anchor_list_item}>
              <AnchorLink to={item.link} className={styles.anchor_list_link}>
                {item.course}
                <span className={styles.underarrow}><UnderArrowIcon /></span>
              </AnchorLink>
            </li>
          ) }
        </ul>
      </section>


      <section className={styles.course_container}>
        {courseHeading(Headings[0].course, Headings[0].linkName)}

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[0].menu[0])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/l_face_01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_face_02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_face_03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_face_04.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
          </div>
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/l_face_set01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_face_set02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[0].menu[1])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/l_vio_01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_vio_02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_vio_03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
          </div>
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/l_vio_set01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_vio_set02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_vio_set03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[0].menu[2])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/l_parts_s.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_parts_m.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_parts_l.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/l_parts_all.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        <ReserveButton />
      </section>


      <section className={styles.course_container}>
        {courseHeading(Headings[1].course, Headings[1].linkName)}

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[1].menu[0])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/g_beard_01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_04.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_05.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_first.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/g_beard_set01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_set02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_beard_set03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[1].menu[1])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/g_vio_01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_vio_02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_vio_03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
          </div>
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/g_vio_set01.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_vio_set02.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_vio_set03.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        {/* メニュー */}
        <div className={styles.course_main}>
          {courseSubHeading(Headings[1].menu[2])}
          <div className={styles.course_box}>
            <StaticImage
              src='../images/course/g_parts_s.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_parts_m.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_parts_l.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth'
            />
            <StaticImage
              src='../images/course/g_parts_all.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
            <StaticImage
              src='../images/course/removal_support.png'
              alt=''
              className={styles.course_image} placeholder='blurred' layout='fullWidth' objectFit='contain'
            />
          </div>
        </div>

        <ReserveButton />
      </section>
    </Layout>
  )
}
