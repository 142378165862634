// extracted by mini-css-extract-plugin
export const contents_container = "course-module--contents_container--wI-ml";
export const top_title = "course-module--top_title--1oqIK";
export const top_heading = "course-module--top_heading--WU3-A";
export const top_heading_icon = "course-module--top_heading_icon--16A24";
export const top_heading_text = "course-module--top_heading_text--1OQzb";
export const anchor_list = "course-module--anchor_list--1bW3e";
export const anchor_list_item = "course-module--anchor_list_item--30bdj";
export const anchor_list_link = "course-module--anchor_list_link--3daDC";
export const underarrow = "course-module--underarrow--Zyfb7";
export const course_heading = "course-module--course_heading--RaR4c";
export const course_main = "course-module--course_main--H0509";
export const course_subheading = "course-module--course_subheading--31a8P";
export const course_box = "course-module--course_box--29djp";
export const course_image = "course-module--course_image--3Q2T7";